import Swiper from 'swiper'

// require('swiper/swiper.scss')
// require('swiper/css/swiper.css')
require('./style.scss')

function start() {
  /**
   * @see https://swiperjs.com/api/
   */
  return new Swiper('.swiper-hero', {
    watchSlidesProgress: true,
    breakpoints: {},
    autoplay: {
      delay: 5000
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets'
    }
  })
}

export default {
  start
}
